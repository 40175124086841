export const getTimeSummary = (
  createdAtTime: Date,
  currentTime: Date
): string => {
  const differenceInMs = Math.abs(
    currentTime.getTime() - createdAtTime.getTime()
  );
  const differenceInMin = Math.floor(differenceInMs / (60 * 1000));
  const differenceInHr = Math.floor(differenceInMin / 60);
  const differenceInDays = Math.floor(differenceInHr / 24);

  const possibilities = [
    {condition: differenceInMin === 0, timeSummary: '< 1 minuto'},
    {condition: differenceInMin === 1, timeSummary: '1 minuto'},
    {
      condition: differenceInMin < 60,
      timeSummary: `${differenceInMin} minutos`,
    },
    {condition: differenceInHr === 1, timeSummary: `1 hora`},
    {condition: differenceInHr < 24, timeSummary: `${differenceInHr} horas`},
    {condition: differenceInDays === 1, timeSummary: `1 dia`},
    {
      condition: differenceInDays <= 10,
      timeSummary: `${differenceInDays} dias`,
    },
  ];

  return (
    possibilities.find(({condition}) => condition)?.timeSummary || '> 10 dias'
  );
};

export const getFullNameInitials = (fullName: string) => {
  const names = fullName.split(' ');
  if (names.length === 1) {
    return names[0].charAt(0);
  }

  const namesLastIndex = names.length - 1;

  const firstName = names[0].toUpperCase();
  const lastName = names[namesLastIndex].toUpperCase();
  return `${firstName[0]}${lastName[0]}`;
};

export const capitalizeFirstLetters = (fullName: string) => {
  if (!fullName) {
    return;
  }

  const arrayOfNames = fullName.trim()?.split(' ');
  return arrayOfNames
    .map((name) =>
      name?.length ? name?.[0]?.toUpperCase() + name.substring(1) : ''
    )
    .join(' ');
};

export const formatCNPJValue = (cnpj: number | string) => {
  if (Number(cnpj)) {
    let cnpjValue: string = cnpj.toString();

    while (cnpjValue && cnpjValue?.length < 14) {
      cnpjValue = '0' + cnpjValue;
    }
    return `${cnpjValue?.slice(0, 2)}.${cnpjValue?.slice(
      2,
      5
    )}.${cnpjValue?.slice(5, 8)}/${cnpjValue?.slice(8, 12)}-${cnpjValue?.slice(
      12
    )}`;
  } else {
    return cnpj;
  }
};

export const formatVehiclePlate = (plate: string) => {
  const rawPlate = cleanSpacesAndSpecialCharacters(plate).toUpperCase();
  if (rawPlate.length === 8) {
    return rawPlate;
  }
  return `${rawPlate.substring(0, 3)}-${rawPlate.substring(3)}`;
};

export const cleanSpacesAndSpecialCharacters = (string: string) => {
  const cleanString = string
    .toLowerCase()
    .trim()
    .replace(/\s/g, '')
    .replace(/á|â|ã|ä|à/g, 'a')
    .replace(/é|ê|ë|è/g, 'e')
    .replace(/í|ì/g, 'i')
    .replace(/ó|ô|ö|õ|ò/g, 'o')
    .replace(/ú|ü|ù/g, 'u');
  return cleanString;
};

export const getNumberWithTwoDecimalPlacesFromString = (
  stringedNumber: string
) => {
  if (!stringedNumber) {
    return;
  }
  const stringedNumberWithoutCommas = stringedNumber.replace(/,/g, '.');
  const numberToFormat = Number(stringedNumberWithoutCommas);
  return formatNumberWithDecimalPlaces(numberToFormat, 2, Math.round);
};

export const formatNumberWithDecimalPlaces = (
  numberToFormat: number,
  decimalPlace: number,
  mathFunction: (n: number) => number
) => {
  const om = Math.pow(10, decimalPlace);
  return mathFunction(numberToFormat * om) / om;
};

export const cleanPhoneNumber = (phone: string) => {
  let cleanPhone: string = phone;

  const charsToReplace = ['(', ')', ' ', '-'];

  charsToReplace.forEach((char) => {
    cleanPhone = cleanPhone.replaceAll(char, '');
  });

  return cleanPhone;
};

export const formatToTwilioPhoneNumber = (
  phone: string,
  prefix = 'whatsapp:'
) => {
  if (!phone) {
    return phone;
  }

  let cleanedPhoneNumber = cleanPhoneNumber(phone);
  cleanedPhoneNumber = removeStringSpecialCharacters(cleanedPhoneNumber);
  cleanedPhoneNumber = removeDdiFromPhoneNumber(cleanedPhoneNumber);

  return `${prefix}+55${cleanedPhoneNumber}`;
};

export const removeStringSpecialCharacters = (string: string) => {
  return string.replace(/[a-zA-Z:+]/g, '');
};
export const removeDdiFromPhoneNumber = (phone: string) => {
  let cleanedPhoneNumber = cleanPhoneNumber(phone);

  if (cleanedPhoneNumber.startsWith('55')) {
    cleanedPhoneNumber = cleanedPhoneNumber.slice(2);
  }

  return cleanedPhoneNumber;
};

export const formatPhoneNumber = (phone: string) => {
  if (!phone) {
    return;
  }

  const phoneLength = phone.length;

  if (phoneLength === 8) {
    return `${phone.slice(0, 4)}-${phone.slice(4)}`;
  }

  if (phoneLength === 9) {
    return `${phone.slice(0, 5)}-${phone.slice(5)}`;
  }

  if (phoneLength === 10) {
    return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;
  }

  if (phoneLength === 11) {
    return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
  }

  if (phoneLength === 12) {
    return `${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(
      4,
      8
    )}-${phone.slice(8)}`;
  }

  if (phoneLength === 13 && !phone.includes('+')) {
    return `${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(
      4,
      9
    )}-${phone.slice(9)}`;
  }

  if (phoneLength === 13 && phone.includes('+')) {
    return `${phone.slice(0, 3)} ${phone.slice(3, 5)} ${phone.slice(
      5,
      9
    )}-${phone.slice(9)}`;
  }

  if (phoneLength === 14) {
    return `${phone.slice(0, 3)} ${phone.slice(3, 5)} ${phone.slice(
      5,
      10
    )}-${phone.slice(10)}`;
  }
};

export function joinWithComma(items: string[], item: string, index: number) {
  return index < items.length - 1 ? `${item}, ` : item;
}

export const formatVehicleData = ({
  vehicle_plate,
  vehicle_brand,
  vehicle_model,
  vehicle_model_year,
  vehicle_version,
}: {
  vehicle_plate?: string | null;
  vehicle_brand?: string | null;
  vehicle_model?: string | null;
  vehicle_model_year?: number | null;
  vehicle_version?: string | null;
}) => {
  if (!vehicle_plate && !vehicle_brand && !vehicle_model) {
    return 'Veículo não informado';
  }

  if (!vehicle_brand && !vehicle_model) {
    return formatVehiclePlate(vehicle_plate || '');
  }

  const vehiclePlate =
    vehicle_plate && `(${formatVehiclePlate(vehicle_plate)})`;
  const vehicleVersion = vehicle_version ? `- ${vehicle_version}` : '';

  return `${vehiclePlate || ''} ${vehicle_brand?.toUpperCase() || ''} ${
    vehicle_model?.toUpperCase() || ''
  } ${vehicle_model_year || ''} ${vehicleVersion}`;
};
