import {PurchaseItem} from './Purchase';
import {AccountInfos} from './Quote';
import {IncidentItemDelivery} from './Incidents';

export interface GetDeliveryType {
  deliveries: Delivery[];
}

export interface Delivery {
  id: number;
  uid: string;
  status: DeliveryStatus;
  departure_full_address: string;
  departure_zip_code: string;
  departure_latitude?: number;
  departure_longitude?: number;
  autopart_provider_name: string;
  autopart_provider_uid: string;
  autopart_provider_warehouse_uid: string;
  arrival_full_address: string;
  arrival_zip_code: string;
  arrival_latitude?: number;
  arrival_longitude?: number;
  client_account_name: string;
  client_account_uid: string;
  delivery_responsible: string;
  next_delivery_id?: number;
  assignment: AccountInfos;
  estimated_delivery_at: string | Date;
  estimated_delivery_time: EstimatedDeliveryTimeTypes | string;
  priority: boolean;
  delivery_request_due_date_at?: string | Date;
  logistics_order_id?: string;
  logistics_provider_uid?: string;
  logistics_provider_name?: string;
  logistics_price?: number | string;
  tracking_link?: string;
  comments?: string;
  created_at: Date;
  updated_at: Date;
  delivery_histories?: DeliveryHistory;
  purchase_items: Array<{purchase_item: PurchaseItem}>;
  must_register_new_delivery?: boolean;
  incident_item_delivery?: IncidentItemDelivery[];
  closed_at?: string | Date;
  logistics_driver_id?: string;
  logistics_driver_name?: string;
  logistics_driver_phone_number?: string;
  logistics_driver_vehicle_plate?: string;
  assigned_drivers_counter: number;
  logistics_driver_assigned_at?: Date | string;
  logistics_driver_latitude?: string;
  logistics_driver_longitude?: string;
  logistics_priority_fee?: number;
  assigned_account_user_uid?: string;
  delivery_changes_histories?: DeliveryChangesHistories[];
  delivery_alert?: string;
}

export type DeliveryChangesHistories = {
  id: number;
  uid: string;
  delivery_id: number;
  action_trigger_account_user_uid: string;
  action_trigger_account_name: string;
  created_at: string | Date;
  logistics_provider_name: string;
  logistics_provider_uid: string;
  logistics_price?: number;
  action_requested: string;
  reason_for_action: string;
  reason_for_action_details?: string;
  logistics_order_id?: string;
  logistics_service_type?: string;
  logistics_driver_id?: string;
  logistics_driver_name?: string;
  logistics_driver_phone_number?: string;
  logistics_driver_vehicle_plate?: string;
  logistics_driver_assigned_at?: string | Date;
  assigned_drivers_counter: number;
  logistics_priority_fee: number;
};

export interface DeliveryHistory {
  id: number;
  uid: string;
  delivery_id: string;
  current_status: string;
  previous_status?: string;
  created_at: Date;
  updated_at: Date;
  is_active: boolean;
}

export type DeliveryStatus =
  | 'waiting_previous_delivery'
  | 'pending_request'
  | 'assigning_driver'
  | 'in_route_to_departure_address'
  | 'waiting_pick_up'
  | 'in_route_to_arrival_address'
  | 'waiting_delivery_confirmation'
  | 'delivered'
  | 'canceled';

export const deliveryStatusDictionary = {
  waiting_previous_delivery: 'Aguardando retirada',
  pending_request: 'Solicitação de logística pendente',
  assigning_driver: 'Atribuindo motorista',
  in_route_to_departure_address: 'A caminho do cliente',
  waiting_pick_up: 'Aguardando retirada',
  in_route_to_arrival_address: 'A caminho da Mecanizou',
  waiting_delivery_confirmation: 'Pendente de confirmação de entrega',
  delivered: 'Entregue',
  canceled: 'Cancelada',
};

export type NewDeliveryModel = {
  logistics_provider_uid: string;
  logistics_order_id?: string;
  logistics_service_type?: string;
  logistics_price: number;
  tracking_link?: string;
  comments?: string;
};

export type DeliveryDriverChangeReasons =
  | ''
  | 'driver_deviated_from_route'
  | 'driver_requested_cancelation'
  | 'driver_does_not_respond'
  | 'driver_was_rude';

export type DeliveryCancelReasons =
  | ''
  | 'warehouse_change'
  | 'stockout'
  | 'order_not_available_for_pick_up'
  | 'logistics_service_type_change'
  | 'order_canceled_by_client'
  | 'driver_too_far_from_warehouse'
  | 'driver_deviated_from_route'
  | 'driver_requested_cancelation'
  | 'driver_does_not_respond'
  | 'driver_was_rude'
  | 'other';

export const deliveryDriverChangeReasonsDictionary = {
  driver_does_not_respond: 'O motorista não responde',
  driver_deviated_from_route: 'O motorista desviou da rota',
  driver_was_rude: 'O motorista foi grosseiro',
  driver_requested_cancelation: 'O motorista solicitou a troca',
};

export const deliveryCancelReasonsDictionary = {
  warehouse_change: 'Troca de filial',
  stockout: 'Furo de estoque',
  order_not_available_for_pick_up:
    'O pedido ainda não está disponível para coleta',
  logistics_service_type_change: 'Troca do tipo de veículo para o transporte',
  order_canceled_by_client: 'Pedido foi cancelado',
  driver_too_far_from_warehouse:
    'O motorista está muito distante do ponto de coleta',
  driver_does_not_respond: 'O motorista não responde',
  driver_deviated_from_route: 'O motorista desviou da rota',
  driver_was_rude: 'O motorista foi grosseiro',
  driver_requested_cancelation: 'O motorista solicitou o cancelamento',
  other: 'Outros',
};

export type PurchaseItemDelivery = {
  uid: string;
  purchase_item_id: number;
  delivery_id: number;
  is_active: boolean;
  delivery: Delivery;
};

export type EstimatedDeliveryTimeTypes =
  | 'express_delivery'
  | 'normal_delivery'
  | 'next_day_morning_delivery'
  | 'next_day_delivery'
  | 'next_period_delivery'
  | 'custom_day_delivery'
  | 'from_5_to_7_days_delivery'
  | 'from_7_to_10_days_delivery'
  | 'from_10_to_15_days_delivery'
  | 'from_15_to_30_days_delivery'
  | 'from_30_to_60_days_delivery'
  | 'from_60_to_90_days_delivery'
  | 'from_90_to_120_days_delivery';

export const deliveryTimeDictionary = {
  express_delivery: 'De 50 a 90 minutos',
  normal_delivery: 'Hoje (até 16h30)',
  next_day_morning_delivery: 'Amanhã (até 09h30)',
  next_day_delivery: 'Amanhã (até 17h30)',
  next_period_delivery: '2 dias úteis',
  custom_day_delivery: '3 dias úteis',
  from_5_to_7_days_delivery: '5 a 7 dias úteis',
  from_7_to_10_days_delivery: '7 a 10 dias úteis',
  from_10_to_15_days_delivery: '10 a 15 dias úteis',
  from_15_to_30_days_delivery: '15 a 30 dias úteis',
  from_30_to_60_days_delivery: '30 a 60 dias úteis',
  from_60_to_90_days_delivery: '60 a 90 dias úteis',
  from_90_to_120_days_delivery: '90 a 120 dias úteis',
};
