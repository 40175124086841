import {deliveryTimeDictionary} from './Delivery';

export type StatusOptions = {
  [key: string]: {
    backgroundColor: string;
    textColor: string;
    text: string;
    icon?: string;
    children?: {
      [key: string]: {
        backgroundColor: string;
        textColor: string;
        text: string;
        icon?: string;
      };
    };
  };
};

export const quoteRequestStatusOptions: StatusOptions = {
  open: {
    textColor: 'white',
    text: 'Solicitação em aberto',
    backgroundColor: 'sand',
  },
  in_progress: {
    textColor: 'warning',
    text: 'Cotação em progresso',
    backgroundColor: 'empty-quarter-80',
  },
  closed: {
    textColor: 'dark',
    text: 'Cotação encerrada',
    backgroundColor: 'info',
  },
};

export const quoteRequestItemStatusOptions: StatusOptions = {
  open: {
    textColor: 'white',
    text: 'Solicitação em aberto',
    backgroundColor: 'sand',
  },
  in_progress: {
    textColor: 'warning',
    text: 'Cotação em progresso',
    backgroundColor: 'empty-quarter-80',
  },
  not_found: {
    textColor: 'white',
    text: 'Item não encontrado',
    backgroundColor: 'vulcan',
  },
  closed: {
    textColor: 'success-active',
    text: 'Cotação finalizada',
    backgroundColor: 'army-80',
  },
  canceled: {
    textColor: 'white',
    text: 'Cotação cancelada',
    backgroundColor: 'vulcan',
  },
};

export const rejectedQuoteStatusOptions: StatusOptions = {
  rejected_price: {
    textColor: 'white',
    text: 'Reprovado preço',
    backgroundColor: 'vulcan',
  },
  rejected_brand: {
    textColor: 'white',
    text: 'Reprovado marca',
    backgroundColor: 'vulcan',
  },
  rejected_timeout: {
    textColor: 'white',
    text: 'Reprovado tempo envio cotação',
    backgroundColor: 'vulcan',
  },
  rejected_client_quit: {
    textColor: 'white',
    text: 'Cliente desistiu do serviço',
    backgroundColor: 'vulcan',
  },
  rejected_deadline_delivery: {
    textColor: 'white',
    text: 'Oficina reprovou prazo sob encomenda',
    backgroundColor: 'vulcan',
  },
  rejected_quote_to_be_done: {
    textColor: 'white',
    text: 'Cotação a ser refeita',
    backgroundColor: 'vulcan',
  },
  reproved_time_to_quote_too_long: {
    textColor: 'white',
    text: 'Reprovado por demora na cotação',
    backgroundColor: 'vulcan',
  },
};

export const situationQuoteStatusOptions: StatusOptions = {
  waiting_customer_approval: {
    textColor: 'white',
    text: 'Aguardando aprovação do cliente',
    backgroundColor: 'naval-80',
  },
  waiting_approval_call_later: {
    textColor: 'white',
    text: 'Aguardando aprovação - ligar mais tarde',
    backgroundColor: 'naval-80',
  },
  not_answering_phone: {
    textColor: 'white',
    text: 'Não atende o telefone',
    backgroundColor: 'naval-80',
  },
};

export const quoteStatusOptions: StatusOptions = {
  open: {textColor: 'white', text: 'Cotação aberta', backgroundColor: 'sand'},
  in_progress: {
    textColor: 'warning',
    text: 'Cotação em progresso',
    backgroundColor: 'empty-quarter-80',
  },
  waiting_approve: {
    textColor: 'primary',
    text: 'Cotação enviada',
    backgroundColor: 'navy-80',
  },
  waiting_stock_share_delivery: {
    textColor: 'white',
    text: 'Aguardando envio da cotação',
    backgroundColor: 'naval-80',
  },
  accepted: {
    textColor: 'success-active',
    text: 'Cotação aceita',
    backgroundColor: 'army-80',
  },
  rejected_price: {
    textColor: 'white',
    text: 'Cotação rejeitada',
    backgroundColor: 'vulcan',
  },
  rejected_brand: {
    textColor: 'white',
    text: 'Cotação rejeitada',
    backgroundColor: 'vulcan',
  },
  rejected_timeout: {
    textColor: 'white',
    text: 'Cotação rejeitada',
    backgroundColor: 'vulcan',
  },
  rejected_client_quit: {
    textColor: 'white',
    text: 'Cotação rejeitada',
    backgroundColor: 'vulcan',
  },
  canceled: {
    textColor: 'white',
    text: 'Cotação cancelada',
    backgroundColor: 'vulcan',
  },
  waiting_customer_approval: {
    textColor: 'empty-quarter-35',
    text: 'Aguardando aprovação do cliente',
    backgroundColor: 'empty-quarter-80',
  },
  waiting_approval_call_later: {
    textColor: 'empty-quarter-35',
    text: 'Aguardando aprovação - ligar mais tarde',
    backgroundColor: 'empty-quarter-80',
  },
  not_answering_phone: {
    textColor: 'white',
    text: 'Não atende o telefone',
    backgroundColor: 'sand',
  },
  rejected_deadline_delivery: {
    textColor: 'white',
    text: 'Cotação rejeitada',
    backgroundColor: 'vulcan',
  },
  rejected_quote_to_be_done: {
    textColor: 'white',
    text: 'Cotação rejeitada',
    backgroundColor: 'vulcan',
  },
  reproved_time_to_quote_too_long: {
    textColor: 'white',
    text: 'Cotação rejeitada',
    backgroundColor: 'vulcan',
  },
  not_found: {
    textColor: 'dark',
    text: 'Cotação encerrada sem itens encontrados',
    backgroundColor: 'info',
  },
};

export const deliveryTypesOptions: StatusOptions = {
  express_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['express_delivery'],
    backgroundColor: 'army-20',
  },
  normal_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['normal_delivery'],
    backgroundColor: 'sand',
  },
  next_day_morning_delivery: {
    textColor: 'primary',
    text: deliveryTimeDictionary['next_day_morning_delivery'],
    backgroundColor: 'navy-80',
  },
  next_day_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['next_day_delivery'],
    backgroundColor: 'vulcan',
  },
  next_period_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['next_period_delivery'],
    backgroundColor: 'vulcan',
  },
  custom_day_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['custom_day_delivery'],
    backgroundColor: 'vulcan',
  },
  from_5_to_7_days_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['from_5_to_7_days_delivery'],
    backgroundColor: 'vulcan',
  },
  from_7_to_10_days_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['from_7_to_10_days_delivery'],
    backgroundColor: 'vulcan',
  },
  from_10_to_15_days_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['from_10_to_15_days_delivery'],
    backgroundColor: 'vulcan',
  },
  from_15_to_30_days_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['from_15_to_30_days_delivery'],
    backgroundColor: 'vulcan',
  },
  from_30_to_60_days_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['from_30_to_60_days_delivery'],    
    backgroundColor: 'vulcan',
  },
  from_60_to_90_days_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['from_60_to_90_days_delivery'],    
    backgroundColor: 'vulcan',
  },
  from_90_to_120_days_delivery: {
    textColor: 'white',
    text: deliveryTimeDictionary['from_90_to_120_days_delivery'],    
    backgroundColor: 'vulcan',
  },
};

export const orderStatusOptions: StatusOptions = {
  waiting_payment_confirmation: {
    backgroundColor: 'night-camo-65',
    textColor: 'night-camo-20',
    text: 'Aguardando pagamento',
  },
  open: {backgroundColor: 'sand', textColor: 'white', text: 'Em aberto'},
  partially_purchased: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Compra de peças iniciada',
  },
  partially_purchased_and_delivered: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Compras e entregas iniciadas',
  },
  finished: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Pedido finalizado',
  },
  canceled: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Pedido cancelado',
  },
};

export const orderItemsStatusOptions: StatusOptions = {
  waiting_purchase: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Aguardando compra',
  },
  partially_purchased: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Compras iniciadas',
  },
  partially_delivered: {
    backgroundColor: 'navy-80',
    textColor: 'navy',
    text: 'Entregas iniciadas',
  },
  pending_cancel_confirmation: {
    backgroundColor: 'navy-20',
    textColor: 'white',
    text: 'Aguardando confirmação de cancelamento',
  },
  delivered: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Entregas finalizadas',
  },
  order_canceled: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Pedido cancelado',
  },
  item_canceled: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Item cancelado',
  },
  canceled_due_out_of_stock: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Item cancelado',
  },
  pending_out_of_stock_resolution: {
    backgroundColor: 'naval-80',
    textColor: 'naval-20',
    text: 'Item com furo de estoque',
  },
  waiting_out_of_stock_customer_approval: {
    backgroundColor: 'naval-80',
    textColor: 'naval-20',
    text: 'Item com furo de estoque',
  },
};

export const orderItemsEventExchangeDevolutionReasons: StatusOptions = {
  missing_autopart_information: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Faltou Informação da Peça',
  },
  client_requested_wrong_autopart: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Mecânica pediu peça errada',
  },
  change_on_autopart_provider: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Mudança de Fornecedor',
  },
  client_info_not_considered: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Informação da mecânica não foi considerada',
  },
  autopart_damaged_on_arrival: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Peça chegou danificada',
  },
  low_quality_autopart: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Peça sem qualidade',
  },
  missing_client_confirmation: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Pedido sem confirmação da mecânica',
  },
  wrong_autopart_on_arrival: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Pediu uma peça e chegou outra',
  },
  request_canceled_by_mechanics_client: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Pedido cancelado pelo cliente da oficina',
  },
  request_canceled_by_client: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Pedido cancelado pela oficina',
  },
  vehicle_wrong_information: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Veículo com informação errada',
  },
};

export const orderItemsCancelationReasons: StatusOptions = {
  canceled_wrong_purchase: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Compra incorreta)',
  },
  canceled_client_quit: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Item cancelado pelo cliente)',
  },
  captation_information_different_from_what_was_requested: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Captação - Informação divergente do solicitado)',
  },
  quotation_incorrect_quoted_part: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Cotação - Peça cotada incorreta)',
  },
  quotation_wrong_catalog: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Cotação - Catálogo errado)',
  },
  quotation_divergent_information_in_retail: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Cotação - Informação divergente no varejo)',
  },
  follow_up_unsolicited_part: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Seguimento - Peça não solicitada)',
  },
  follow_up_wrong_amount: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Seguimento - Quantidade errada)',
  },
  sales_provider_change: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Compras - Mudança de fornecedor)',
  },
  sales_incorrect_shipped_quantity: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Compras - Quantidade enviada incorreta)',
  },
  sales_divergent_code: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Compras - Código divergente)',
  },
  provider_warranty_up_to_3_months_from_sale: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Fornecedor - Garantia até 3 meses da venda)',
  },
  provider_warranty_after_up_to_3_months_of_sale: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Fornecedor - Garantia após até 3 meses de venda)',
  },
  provider_wrong_shipment: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Fornecedor - Expedição errada)',
  },
  provider_item_damaged_on_delivery_day: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Fornecedor - Peça com avaria no dia da entrega)',
  },
  provider_lower_quality_part: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Fornecedor - Peça de qualidade inferior)',
  },
  provider_warehouse_shortage: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Fornecedor - Furo de estoque no Fornecedor)',
  },
  provider_wrong_delivered_quantity: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Fornecedor - Quantidade entregue errada)',
  },
  logistics_delay_in_order: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Logística - Atraso no pedido)',
  },
  mechanics_wrong_requested_information: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Mecânica - Informação solicitada errada)',
  },
  mechanics_withdrew_from_purchase: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Cancelado (Mecânica - Desistiu da compra)',
  },
};

export const purchaseRequestStatusOptions: StatusOptions = {
  waiting_purchase: {
    backgroundColor: 'yellow-100',
    textColor: 'yellow-600',
    text: 'Aguardando compra',
  },
  partially_purchased: {
    backgroundColor: 'orange-100',
    textColor: 'orange-700',
    text: 'Compras iniciadas',
  },
  purchased: {
    backgroundColor: 'green-100',
    textColor: 'green-900',
    text: 'Compras concluídas',
  },
  canceled: {
    backgroundColor: 'gray-200',
    textColor: 'gray-900',
    text: 'Pedido cancelado',
  },
  unassigned: {
    backgroundColor: 'red-100',
    textColor: 'red-500',
    text: 'Necessário atribuição',
  },
};

export const automaticPurchaseRequestStatusOptions: StatusOptions = {
  not_integrated: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Fornecedor cotado não integrado',
  },
  skipped_distance_exceeded: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Fornecedor cotado muito distante',
  },
  skipped_margin_not_reached: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Margem baixa no fornecedor cotado',
  },
  skipped_in_stock: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Item no estoque Mecanizou',
  },
  failed: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Falha na compra automática',
  },
};

export const deliveryStatusOptions: StatusOptions = {
  waiting_previous_delivery: {
    backgroundColor: 'yellow-100',
    textColor: 'yellow-900',
    text: 'Aguardando chegada na Mecanizou',
  },
  pending_request: {
    backgroundColor: 'red-100',
    textColor: 'red-900',
    text: 'Solicitação de logística pendente',
  },
  assigning_driver: {
    backgroundColor: 'purple-100',
    textColor: 'purple-900',
    text: 'Atribuindo motorista',
  },
  in_route_to_departure_address: {
    backgroundColor: 'cyan-100',
    textColor: 'cyan-900',
    text: 'A caminho da partida',
  },
  waiting_pick_up: {
    backgroundColor: 'yellow-100',
    textColor: 'yellow-900',
    text: 'Aguardando retirada',
  },
  in_route_to_arrival_address: {
    backgroundColor: 'blue-100',
    textColor: 'blue-900',
    text: 'A caminho da entrega',
  },
  waiting_delivery_confirmation: {
    backgroundColor: 'red-100',
    textColor: 'red-900',
    text: 'Confirmação de entrega pendente',
  },
  delivered: {
    backgroundColor: 'green-100',
    textColor: 'green-900',
    text: 'Entregue',
  },
  canceled: {
    backgroundColor: 'gray-200',
    textColor: 'gray-900',
    text: 'Cancelado',
  },
};

export const followUpStatusOptions: StatusOptions = {
  send_quote_to_mechanic: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Pendente de envio',
  },
  normal_follow_up: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Pendente de seguimento',
  },
  fast_follow_up: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Seguimento urgente',
  },
  closed: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Seguimento concluído',
  },
  open: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Seguimento pendente',
  },
  in_progress: {
    textColor: 'warning',
    text: 'Seguimento em progresso',
    backgroundColor: 'empty-quarter-80',
  },
};

export const cancelledItemsStatusOptions: StatusOptions = {
  to_be_determined: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'A definir',
  },
  return_to_mecanizou: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Aguardando retorno pra Mecanizou',
  },
  return_to_provider: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'warning',
    text: 'Aguardando retorno pro Fornecedor',
  },
  obsolete_item: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Item obsoleto',
  },
  stock_item: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Item para estoque',
  },
  returned_item_accepted: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Devolução aceita',
  },
};

export const incidentItemAtStockOptions: StatusOptions = {
  already_has_return_tax_receipt: {
    backgroundColor: 'navy-80',
    textColor: 'navy-20',
    text: 'Nota de devolução emitida',
  },

  missing_tax_info: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Faltam Informações De Nota Fiscal',
  },
  missing_item_tax_info: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Emissão de NF bloqueada por falta de informações do item',
  },
  missing_provider_return_code: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'warning',
    text: 'Código De autoriação Não Informado',
  },
  missing_original_access_key: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'warning',
    text: 'Chave De Acesso Original Não Informada',
  },
  able_to_emit_tax_receipt: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Liberado para emissão de NF de devolução',
  },
  to_be_determined: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'A definir',
  },
};

export const paymentTransactionStatusOptions: StatusOptions = {
  open: {backgroundColor: 'sand', textColor: 'white', text: 'Em aberto'},
  processing_payment: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Pagamento em processo',
  },
  paid: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Pago'},
  refused: {backgroundColor: 'danger', textColor: 'white', text: 'Recusado'},
  overdue: {backgroundColor: 'vulcan', textColor: 'white', text: 'Vencido'},
  paid_with_balance: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Pago com crédito',
  },
  renegotiated: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Renegociado',
  },
  scheduled: {backgroundColor: 'sand', textColor: 'white', text: 'Agendado'},
};

export const transactionStatusOptions: StatusOptions = {
  processing_payment: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Pagamento em processo',
  },
  canceled: {backgroundColor: 'vulcan', textColor: 'white', text: 'Cancelado'},
  paid: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Pago'},
  refused: {backgroundColor: 'danger', textColor: 'white', text: 'Recusado'},
  overdue: {backgroundColor: 'vulcan', textColor: 'white', text: 'Vencido'},
  scheduled: {backgroundColor: 'sand', textColor: 'white', text: 'Agendado'},
};

export const expectedPaymentStatusOptions: StatusOptions = {
  on_time: {backgroundColor: 'sand', textColor: 'white', text: 'Agendado'},
  overdue: {backgroundColor: 'vulcan', textColor: 'white', text: 'Atrasado'},
  delinquent: {backgroundColor: 'danger', textColor: 'white', text: 'Vencido'},
  paid: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Pago'},
  agreed_payment: {
    backgroundColor: 'army-65',
    textColor: 'black',
    text: 'Renegociado',
  },
  canceled: {backgroundColor: 'vulcan', textColor: 'white', text: 'Cancelado'},
  refunded: {backgroundColor: 'navy-35', textColor: 'white', text: 'Estornado'},
};

export const paymentBillStatusOptions: StatusOptions = {
  open: {backgroundColor: 'sand', textColor: 'white', text: 'Em aberto'},
  processing_payment: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Pagamento em processo',
  },
  paid: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Pago'},
  refused: {backgroundColor: 'danger', textColor: 'white', text: 'Recusado'},
  overdue: {backgroundColor: 'vulcan', textColor: 'white', text: 'Vencido'},
  paid_with_balance: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Pago com crédito',
  },
  renegotiated: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Renegociado',
  },
  scheduled: {backgroundColor: 'sand', textColor: 'white', text: 'Agendado'},
  created: {backgroundColor: 'sand', textColor: 'white', text: 'Criada'},
  registered: {backgroundColor: 'sand', textColor: 'white', text: 'Registrada'},
  failed: {backgroundColor: 'danger', textColor: 'white', text: 'Falha'},
  canceled: {backgroundColor: 'danger', textColor: 'white', text: 'Cancelado'},
  partial_payment: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Pagamento Parcial',
  },
};

export const invoiceStatusOptions: StatusOptions = {
  open: {backgroundColor: 'sand', textColor: 'white', text: 'Fatura em aberto'},
  closed: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Fechada'},
  canceled: {backgroundColor: 'vulcan', textColor: 'white', text: 'Cancelada'},
  partially_paid: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'empty-quarter',
    text: 'Paga parcialmente',
  },
  no_value_to_pay: {
    backgroundColor: 'naval-80',
    textColor: 'white',
    text: 'Sem valor a pagar',
  },
  paid: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Fatura paga'},
};

export const invoiceEntryStatusOptions: StatusOptions = {
  cancelation: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Crédito',
  },
  sale: {backgroundColor: 'vulcan', textColor: 'white', text: 'Débito'},
};

export const taxReceiptStatusOptions: StatusOptions = {
  queued_for_processing: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Em fila para processamento',
  },
  processing_creation: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'warning',
    text: 'Em processamento',
  },
  created: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Criada'},
  error_missing_legal_details: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Erro: Faltam informações legais',
  },
  error_missing_items_tax_parameters: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Erro: Faltam informações fiscais dos produtos',
  },
  error_unable_to_process: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Erro no processamento',
  },
  error_unable_to_cancel: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Erro no cancelamento',
  },
  canceled: {backgroundColor: 'vulcan', textColor: 'white', text: 'Cancelada'},
  error_rejection_sefaz: {
    backgroundColor: 'vulcan',
    textColor: 'white',
    text: 'Erro: Emissão rejeitada pelo SEFAZ',
  },
  queued_for_cancel: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Em fila para processamento',
  },
};

export const statementStatusOptions: StatusOptions = {
  scheduled: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Aguardando pagamento',
  },
  renegotiated: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'warning',
    text: 'Renegociado',
  },
  overdue: {backgroundColor: 'vulcan', textColor: 'white', text: 'Atrasado'},
  confirmed: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Pagamento confirmado',
  },
};

export const PaymentStatusOptions: StatusOptions = {
  scheduled: {
    backgroundColor: 'sand',
    textColor: 'white',
    text: 'Aguardando pagamento',
  },
  renegotiated: {
    backgroundColor: 'empty-quarter-80',
    textColor: 'warning',
    text: 'Renegociado',
  },
  overdue: {backgroundColor: 'vulcan', textColor: 'white', text: 'Atrasado'},
  canceled: {
    textColor: 'white',
    text: 'Pagamento Cancelado',
    backgroundColor: 'vulcan',
  },
  paid: {backgroundColor: 'army-80', textColor: 'army-20', text: 'Pago'},
};

export const LogisticsTypeOptions: StatusOptions = {
  economics: {
    backgroundColor: 'white',
    textColor: 'primary',
    text: 'Econômica',
    icon: 'bi-piggy-bank',
  },
  express: {
    backgroundColor: 'white',
    textColor: 'primary',
    text: 'Express',
    icon: 'bi-lightning',
  },
  scheduled: {
    backgroundColor: 'white',
    textColor: 'primary',
    text: 'Agendada',
    icon: 'bi-calendar3-event',
  },
};

export const CustomerLevelOptions: StatusOptions = {
  bronze: {
    backgroundColor: '#AF5D21',
    textColor: 'white',
    text: 'Bronze',
  },
  silver: {
    backgroundColor: '#6E727B',
    textColor: 'white',
    text: 'Prata',
  },
  gold: {
    backgroundColor: '#DCA745',
    textColor: 'white',
    text: 'Ouro',
  },
  diamond: {
    backgroundColor: '#1F242C',
    textColor: 'white',
    text: 'Diamante',
  },
};

export const orderStatusEventsOptions: StatusOptions = {
  order_created: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Pedido criado',
  },
  waiting_payment: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Aguardando pagamento do pedido',
  },
  exchange_analysis: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Pedido de troca em análise',
  },
  payment_promise: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Promessa de pagamento do pedido',
  },
  payment_authorized: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Pagamento autorizado do pedido',
  },
  payment_exempt: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Pedido isento de pagamento',
  },
  payment_invoiced: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Pagamento faturado do pedido',
  },
  payment_captured: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Pagamento do pedido capturado',
  },
  preparing_order: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Pedido em separação',
  },
  preparing_delivery: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Preparando para entrega',
  },
  delivery_in_progress: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Entrega do pedido em andamento',
  },
  order_delivered: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Pedido entregue',
  },
  order_analysis: {
    backgroundColor: 'purple-100',
    textColor: 'purple-900',
    text: 'Pedido em análise',
  },
  manually_canceled_by_lack_of_payment: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Cancelado manualmente - Falta de pagamento',
  },
  automatically_canceled_by_lack_of_payment: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Cancelado automaticamente - Falta de pagamento',
  },
  automatically_canceled_by_gateway: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Cancelado automaticamente - Autorização de compra negada pelo gateway',
  },
  manually_canceled_by_customer_request: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Cancelado por solicitação do cliente',
  },
};

export const orderStatusStageOptions: StatusOptions = {
  order_checkout: {
    textColor: 'navy',
    backgroundColor: 'gray-100',
    text: 'Checkout',
  },
  purchase: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Compras',
  },
  delivery: {
    textColor: 'army-20',
    backgroundColor: 'army-80',
    text: 'Entrega',
  },
  cancellation: {
    textColor: 'dark-vulcan',
    backgroundColor: 'red-100',
    text: 'Cancelamento',
  },
  customer_service: {
    textColor: 'purple-900',
    backgroundColor: 'purple-100',
    text: 'Customer Service',
  },
};

export const orderItemStatusEventOptions = {
  in_shopping_queue: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Na fila de compras',
  },
  automatic_purchase_started: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Compra automática iniciada',
  },
  manual_purchase_started: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Compra manual iniciada',
  },
  attendant_transfer: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Transferência de atendente',
  },
  partially_purchased: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Compra parcialmente realizada',
  },
  purchase_completed: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Compra realizada',
  },
  out_of_stock: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Acabou no estoque',
  },
  searching_new_supplier: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Procurando novo fornecedor',
  },
  supplier_change: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Mudança de fornecedor',
  },
  item_unavailable_in_market: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Item indisponível no mercado',
  },
  new_options_sent_to_customer: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Novas opções enviadas para o cliente',
  },
  item_change: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Mudança de item',
  },
  awaiting_new_offer_acceptance: {
    textColor: 'empty-quarter-35',
    backgroundColor: 'empty-quarter-80',
    text: 'Aguardando aceite de nova oferta',
  },
  item_pending_cancel_confirmation: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Item aguardando confirmação de cancelamento',
  },
  item_cancelation_confirmed: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Cancelamento do item confirmado',
  },
  item_canceled_by_out_of_stock: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Item cancelado - Falta de estoque',
  },
  item_canceled_by_no_acceptance: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Item cancelado - Cliente não aceitou nova cotação',
  },
  logistics_request_pending: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Solicitação de logística pendente',
  },
  automatic_logistics_request: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Solicitação de logísticas automáticas',
  },
  manual_logistics_request: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Solicitação de logísticas manuais',
  },
  item_unavailable_from_supplier_on_purchase: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Inidsponibilidade do item no fornecedor',
  },
  assigning_driver: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Atribuindo motorista',
  },
  driver_assigned: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Motorista atribuído',
  },
  en_route_to_pickup: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'A caminho da partida',
  },
  awaiting_pickup: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Aguardando retirada no fornecedor',
  },
  pickup_confirmed: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Confirmação de retirada no fornecedor',
  },
  contacting_courier: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Fazer contato com o motoboy',
  },
  item_en_route_to_delivery: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Item a caminho da entrega',
  },
  item_rescue: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Resgate do item',
  },
  contacting_logistics_provider: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Comunicando o provedor logístico',
  },
  item_delivered: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Item entregue',
  },
  item_lost: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Extravio do item',
  },
  item_unavailable_from_supplier_on_delivery: {
    backgroundColor: 'army-80',
    textColor: 'army-20',
    text: 'Indisponibilidade do item no fornecedor',
  },
  item_under_review: {
    textColor: 'purple-900',
    backgroundColor: 'purple-100',
    text: 'Item em análise',
  },
  item_canceled_via_ticket: {
    backgroundColor: 'red-100',
    textColor: 'dark-vulcan',
    text: 'Item cancelado via ticket',
  },
};
