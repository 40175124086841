import {add, format, parseJSON,} from 'date-fns';

const getDeadlines = (deliveryTime: string, initialTime: string | Date) => {
      switch (deliveryTime) {
        case 'express_delivery':
          return add(new Date(initialTime), {minutes:55});
    
        case 'normal_delivery':
          return add(parseJSON(add(new Date(format(new Date(initialTime), 'yyyy-MM-dd')), {hours:3})), {hours: 16, minutes:30});
    
        case 'next_day_morning_delivery':
          return add(parseJSON(add(new Date(format(new Date(initialTime), 'yyyy-MM-dd')), {hours:3})), {hours: 36, minutes:30});
    
        case 'next_day_delivery':
          return add(parseJSON(add(new Date(format(new Date(initialTime), 'yyyy-MM-dd')), {hours:3})), {hours: 41, minutes:30});
    
        case 'next_period_delivery':
          return add(new Date(initialTime), {days:2})
    
        case 'custom_day_delivery':
          return add(new Date(initialTime), {days:3})
    
        case 'from_5_to_7_days_delivery':
        return add(new Date(initialTime), {days:7})

        case 'from_7_to_10_days_delivery':
        return add(new Date(initialTime), {days:10})

        case 'from_10_to_15_days_delivery':
        return add(new Date(initialTime), {days:15})

        case 'from_15_to_30_days_delivery':
        return add(new Date(initialTime), {days:30})

        case 'from_30_to_60_days_delivery':
        return add(new Date(initialTime), {days:60})

        case 'from_60_to_90_days_delivery':
        return add(new Date(initialTime), {days:90})

        case 'from_90_to_120_days_delivery':
        return add(new Date(initialTime), {days:120})

        default:
          break;
      }
};

export {getDeadlines};